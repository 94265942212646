
export const FANTOM = 250;

export const addresses = {

  [FANTOM]: {
    EQUAL: "0x3Fd3A0c85B70754eFc07aC9Ac0cbBDCe664865A6",
    ES_EQUAL: "0xDFedB4cEa56a4FB74BC4811Be3c47AD7eb011B5f",
    EQUITY: "0x0d90b147fB412fB8469B82bc73623FF8e19FCCf0",
    BN_EQUAL: "0xDf3B12825c2aD499C21E36eFc28803a82Fd73e58",
    STAKED_EQUAL_TRACKER: "0x8682e4d5D7Fd68E152a0F13f6A8f45BD004babeB", 
    STAKED_EQUITY_TRACKER: "0xac941d23365A2F7278a4fd41041E5C85373AD47e", 
    BONUS_EQUAL_TRACKER: "0x3A093954508Fc0f63eF4dd423c6C33F88F248873",
    FEE_EQUAL_TRACKER: "0x5fEbB20a3674A5d4Ac8CC2e45d1cda8a8f90F508", 
    FEE_EQUITY_TRACKER: "0x0454a90a6E0E525E273A9e3173e1aE2aFD9EEDc6", 
    FTM: "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83",
    // BTC: "0xf1648C50d2863f780c57849D812b4B7686031A3D", // 8 decimals wrapped btc
    ETH: "0x695921034f0387eAc4e11620EE91b1b15A6A09fE", // 18 decimals wrapped eth
    RewardReader: "0x03F7ad0A6546a367A78cfd65159a86cE9E029EC5", 
    EquityManager: "0x9ca9805dBAcE7AeE0565c546145CA105B202a979", 
    Router: "0x9E4fB3C60a08a0685d32C2A7788843C440171060", 
    OrderBook: "0x380Bd52Af7dB3B5173c18F89f5f3BF11A8E2c7dC", 
    PositionManager: "0x940407988c4B94FE95EE4e0a91983015f765B2B3", 
    FastPriceFeed: "0x384c257bB6C30c24190CCF2Ec5806901272062F4", 
    PositionRouter: "0x6690ADb6445971B04524abE951a18d230e863ECa", 
    PositionExecutorUpKeep: ""
  },
};

export function getAddress(chainId, key) {
  if (!chainId in addresses) {
    throw new Error(`Unknown chain ${chainId}`);
  }
  if (!(key in addresses[chainId])) {
    throw new Error(`Unknown address key ${key}`);
  }
  return addresses[chainId][key];
}
